import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";
import axios from 'axios'; // 自己创建一个axios对象

const request = axios.create({
  //     baseURL: 'http://69.231.185.234',   // 基础路径，默认是/ ，如果改了，会自动添加到你请求url前面
  timeout: 0 // 请求超时，5000毫秒

}); // 请求拦截器

request.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json'; // 在发送请求之前做些什么
  // config.headers['Cookie'] =document.cookie

  return config;
}, function (error) {
  // 处理请求错误
  return Promise.reject(error);
});
let isAlertShown = false; // 响应拦截器

request.interceptors.response.use(response => {
  // 在2xx范围内的任何状态代码都会触发此功能
  // 处理响应数据
  if (response?.data?.code == -9998) {
    _Message.error('暂时无权访问该资源,请重新登录');

    setTimeout(() => {
      location.href = '/login';
    }, 500);
  }

  return response;
}, function (error) {
  if (location.pathname !== '/login') {
    if (error.response.status === 401 && !isAlertShown) {
      // 401 说明 token 验证失败
      // 可以直接跳转到登录页面，重新登录获取 token
      _Message.error('登录超时，请重新登录');

      localStorage.setItem('userStatus', 'logout');
      setTimeout(() => {
        window.location.href = '/login';
      }, 500);
      isAlertShown = true;
    }
  } // 任何超出2xx范围的状态代码都会触发此功能
  // 处理响应错误


  return Promise.reject(error);
});
export default request; // 导出自定义创建的 axios 对象