import Mock from "mockjs";
import setupMock from "@/utils/setupMock";
const haveReadIds = [];

const deleteMessage = id => {
  const index = haveReadIds.indexOf(id);

  if (index !== -1) {
    haveReadIds.splice(index, 1);
  }
};

const getMessageList = () => [// ... (your original array)
{
  key: 1,
  name: "产品A",
  description: "这是产品A的描述",
  classification: "a分类",
  address: "张某某",
  statusbox1: 1,
  export: "https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/11.27反馈文档.docx"
}, {
  key: 2,
  name: "产品B",
  description: "这是产品B的描述",
  classification: "b分类",
  address: "袁某某",
  statusbox1: 1,
  export: "https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/11.27反馈文档.docx"
}, {
  key: 3,
  name: "产品C",
  description: "这是产品C的描述",
  classification: "c分类",
  address: "某某",
  statusbox1: 0,
  export: "https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/11.27反馈文档.docx"
}, {
  key: 4,
  name: "产品C",
  description: "这是产品C的描述",
  classification: "c分类",
  address: "陈某某",
  statusbox1: 0,
  export: "https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/11.27反馈文档.docx"
}, {
  key: 5,
  name: "产品C",
  description: "这是产品C的描述",
  classification: "c分类",
  address: "陈某某",
  statusbox1: 0,
  export: "https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/11.27反馈文档.docx"
}, {
  key: 6,
  name: "产品C",
  description: "这是产品C的描述",
  classification: "c分类",
  address: "陈某某",
  statusbox1: 1,
  export: "https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/11.27反馈文档.docx"
}, {
  key: 7,
  name: "产品C",
  description: "这是产品C的描述",
  classification: "c分类",
  address: "陈某某",
  statusbox1: 0,
  export: "https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/11.27反馈文档.docx"
}, {
  key: 8,
  name: "产品C",
  description: "这是产品C的描述",
  classification: "c分类",
  address: "陈某某",
  statusbox1: 0,
  export: "https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/11.27反馈文档.docx"
}, {
  key: 9,
  name: "产品C",
  description: "这是产品C的描述",
  classification: "c分类",
  address: "陈某某",
  statusbox1: 0,
  export: "https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/11.27反馈文档.docx"
}, {
  key: 10,
  name: "产品C",
  description: "这是产品C的描述",
  classification: "c分类",
  address: "陈某某",
  statusbox1: 0,
  export: "https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/11.27反馈文档.docx"
}, {
  key: 11,
  name: "产品C",
  description: "这是产品C的描述",
  classification: "c分类",
  address: "陈某某",
  statusbox1: 0,
  export: "https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/11.27反馈文档.docx"
}];

let messageList = getMessageList();
const updatedList = messageList.map(item => ({ ...item,
  statusbox: haveReadIds.indexOf(item.key) === -1 ? 0 : 1
}));

const handleDelete = id => {
  console.log("Deleting message with id:", id);

  if (id != null) {
    // Update the messageList by filtering out the deleted item
    messageList = messageList.filter(item => item.key !== id);
    console.log("Updated list after deletion:", messageList);
    return true; // Return true to indicate successful deletion
  } else {
    console.error("Invalid id for deletion:", id);
    return false;
  }
};

setupMock({
  setup: () => {
    Mock.mock(new RegExp("/api/message/list"), () => {
      return messageList;
    });
    Mock.mock(new RegExp("/api/message/read"), params => {
      const {
        ids
      } = JSON.parse(params.body);
      haveReadIds.push(...(ids || []));
      return true;
    });
    Mock.mock(new RegExp("/api/message/delete"), params => {
      try {
        const parsedBody = JSON.parse(params.body);

        if (parsedBody && typeof parsedBody === "object" && "id" in parsedBody) {
          const {
            id
          } = parsedBody;
          return handleDelete(id);
        } else {
          console.error('Invalid JSON structure or missing "id" property:', params.body);
          return false;
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return false;
      }
    });
  }
});