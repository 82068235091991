// 批次管理
import Mock from "mockjs";
import setupMock from "@/utils/setupMock";
const haveReadIds = [];

const deleteMessage = id => {
  const index = haveReadIds.indexOf(id);

  if (index !== -1) {
    haveReadIds.splice(index, 1);
  }
};

const getMessageList = () => [// ... (your original array)
{
  key: 101,
  batch_name: "批次1",
  file_ids: [1, 2],
  created_date: "2023-01-15",
  last_modified_date: "2023-01-20",
  status: "1",
  owner: "张三"
}, {
  key: 102,
  batch_name: "批次2",
  file_ids: [2, 3],
  created_date: "2023-02-10",
  last_modified_date: "2023-02-15",
  status: "2",
  owner: "李四"
}, {
  key: 103,
  batch_name: "批次3",
  file_ids: [1, 3],
  created_date: "2023-03-25",
  last_modified_date: "2023-03-30",
  status: "1",
  owner: "王五"
}, {
  key: 104,
  batch_name: "批次1",
  file_ids: [1, 2],
  created_date: "2023-01-15",
  last_modified_date: "2023-01-20",
  status: "2",
  owner: "张三"
}, {
  key: 105,
  batch_name: "批次2",
  file_ids: [2, 3],
  created_date: "2023-02-10",
  last_modified_date: "2023-02-15",
  status: "1",
  owner: "李四"
}, {
  key: 106,
  batch_name: "批次3",
  file_ids: [1, 3],
  created_date: "2023-03-25",
  last_modified_date: "2023-03-30",
  status: "2",
  owner: "王五"
}, {
  key: 107,
  batch_name: "批次1",
  file_ids: [1, 2],
  created_date: "2023-01-15",
  last_modified_date: "2023-01-20",
  status: "2",
  owner: "张三"
}, {
  key: 108,
  batch_name: "批次2",
  file_ids: [2, 3],
  created_date: "2023-02-10",
  last_modified_date: "2023-02-15",
  status: "1",
  owner: "李四"
}, {
  key: 109,
  batch_name: "批次3",
  file_ids: [1, 3],
  created_date: "2023-03-25",
  last_modified_date: "2023-03-30",
  status: "1",
  owner: "王五"
}, {
  key: 110,
  batch_name: "批次1",
  file_ids: [1, 2],
  created_date: "2023-01-15",
  last_modified_date: "2023-01-20",
  status: "2",
  owner: "张三"
}, {
  key: 111,
  batch_name: "批次2",
  file_ids: [2, 3],
  created_date: "2023-02-10",
  last_modified_date: "2023-02-15",
  status: "1",
  owner: "李四"
}, {
  key: 112,
  batch_name: "批次3",
  file_ids: [1, 3],
  created_date: "2023-03-25",
  last_modified_date: "2023-03-30",
  status: "2",
  owner: "王五"
}];

let messageList = getMessageList();
const updatedList = messageList.map(item => ({ ...item,
  statusbox: haveReadIds.indexOf(item.key) === -1 ? 0 : 1
}));

const handleDelete = id => {
  console.log("Deleting message with id:", id);

  if (id != null) {
    // Update the messageList by filtering out the deleted item
    messageList = messageList.filter(item => item.key !== id);
    console.log("Updated list after deletion:", messageList);
    return true; // Return true to indicate successful deletion
  } else {
    console.error("Invalid id for deletion:", id);
    return false;
  }
};

setupMock({
  setup: () => {
    Mock.mock(new RegExp("/api/taskmanagement/list"), () => {
      return messageList;
    });
    Mock.mock(new RegExp("/api/message/read"), params => {
      const {
        ids
      } = JSON.parse(params.body);
      haveReadIds.push(...(ids || []));
      return true;
    });
    Mock.mock(new RegExp("/api/taskmanagement/delete"), params => {
      try {
        const parsedBody = JSON.parse(params.body);

        if (parsedBody && typeof parsedBody === "object") {
          if ("id" in parsedBody) {
            const {
              id
            } = parsedBody;
            return handleDelete(id);
          } else if ("ids" in parsedBody && Array.isArray(parsedBody.ids)) {
            const {
              ids
            } = parsedBody;
            const results = ids.map(id => handleDelete(Number(id))); // Parse id as a number

            return results;
          } else {
            console.error('Invalid JSON structure or missing "id" or "ids" property:', params.body);
            return false;
          }
        } else {
          console.error("Invalid JSON structure:", params.body);
          return false;
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return false;
      }
    });
  }
});